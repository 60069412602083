import useAuth from '@/use/useAuth';
import { createWebHistory, createRouter, RouterView } from 'vue-router';

const { user, token } = useAuth();

const routes = [
    {
        path: '/',
        name: 'Index',
        redirect: '/login',
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/public/Login.vue'),
    },
    {
        path: '/recovery',
        name: 'Validar correo para recuperar contraseña campestre',
        component: () => import('@/views/public/RecoveryRequest.vue'),
    },
    {
        path: '/recovery/:token',
        name: 'Recuperar contraseña campestre',
        component: () => import('@/views/public/Recovery.vue'),
    },
    {
        path: '/proveedor',
        name: 'Proveedor',
        component: () => import('@/views/sistema/Index.vue'),
        meta: {
            auth: true,
            roles: [],
        },
        children: [
            {
                path: 'mi-cuenta',
                name: 'Configuración Cuenta',
                component: RouterView,
                meta: {
                    icon: 'ni-folder',
                    roles: ['proveedor'],
                    menu: false,
                    deep: false,
                    show: []
                },
                redirect:{
                    name:'Mi Cuenta'
                },
                children: [
                    {
                        path: '',
                        name: 'Mi Cuenta',
                        component: () =>
                            import(
                                '@/views/proveedor/cuenta/Cuenta.vue'
                            ),
                    },
                ]
            },
            {
                path: 'documentacion',
                name: 'Documentación',
                component: RouterView,
                meta: {
                    icon: 'ni-folder',
                    roles: ['proveedor'],
                    menu: true,
                    deep: false,
                    show: []
                },
                children: [
                    {
                        path: '',
                        name: 'Mi documentación',
                        component: () =>
                            import(
                                '@/views/proveedor/documentacion/Documentacion.vue'
                            ),
                    },
                ]
            },
            {
                path:'cotizaciones',
                name:'Cotizaciones',
                component: RouterView,
                meta: {
                    icon: 'ni-coin-alt',
                    roles: ['proveedor'],
                    menu: true,
                    deep: false,
                    show: []
                },
                children: [
                    {
                        
                        path: '',
                        name: 'Mis cotizaciones',
                        component: () => import(
                            '@/views/proveedor/cotizaciones/Cotizaciones.vue'
                        ),
                    }
                ]
            },
            {
                path:'ordenes-compra',
                name:'Ordenes de compra',
                component: RouterView,
                meta: {
                    icon: 'ni-coin-alt',
                    roles: ['proveedor'],
                    menu: true,
                    deep: false,
                    show: []
                },
                children: [
                    {
                        
                        path: '',
                        name: 'Mis Ordenes de Compra',
                        component: () => import(
                            '@/views/proveedor/ordenes-compra/OrdenesCompra.vue'
                        ),
                    },
                    {
                        
                        path: 'ver-orden/:id',
                        name: 'Mi Orden de compra',
                        component: () => import(
                            '@/views/proveedor/ordenes-compra/OrdenCompra.vue'
                        ),
                    },

                ]
            }
        ]
    },
    {
        path: '/sistema',
        name: 'Sistema',
        component: () => import('@/views/sistema/Index.vue'),
        meta: {
            auth: true,
            roles: [],
        },
        children: [
            {
                path: 'configuracion',
                name: 'Configuración general',
                component: RouterView,
                meta: {
                    icon: 'ni-globe',
                    roles: [],
                    menu: true,
                    deep: true,
                    show: [
                        {padre: 'Configuración general / Variables', permiso: 'Ver módulo'},
                    ]
                },
                children: [
                    {
                        meta: {
                            deep: false,
                            roles: [
                                'superadmin',
                                '1be6be1c-f46b-11eb-b8a2-0242ac130002',
                            ],
                            show: [
                                {padre: 'Configuración general / Variables', permiso: 'Ver módulo'},
                            ]
                        },
                        path: 'variables',
                        name: 'Variables',
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Lista variables',
                                component: () =>
                                    import(
                                        '@/views/sistema/configuracion/variables/Variables.vue'
                                    ),
                            },
                            {
                                path: 'editar/:id',
                                name: 'Editar variable',
                                component: () =>
                                    import(
                                        '@/views/sistema/configuracion/variables/Variable.vue'
                                    ),
                            },
                        ],
                    },
                ]
            },
            {
                path: 'administracion',
                name: 'Administración',
                component: RouterView,
                meta: {
                    icon: 'ni-setting-fill',
                    roles: [],
                    menu: true,
                    deep: true,
                    show: [
                        {padre: 'Administración / Usuarios', permiso: 'Ver módulo'},
                        {padre: 'Administración / Perfiles y permisos', permiso: 'Ver módulo'},                        
                        {padre: 'Administración / Departamentos', permiso: 'Ver módulo'},                 
                        {padre: 'Administración / Tipos de membresías', permiso: 'Ver módulo'},                        
                        {padre: 'Administración / Accesos', permiso: 'Ver módulo'},
                        {padre: 'Administración / Activos', permiso: 'Ver módulo'},           
                        {padre: 'Administración / Productos / Almacenes', permiso: 'Ver módulo'},
                        {padre: 'Administración / Productos / Grupos de productos', permiso: 'Ver módulo'},
                        {padre: 'Administración / Productos / Lista de productos', permiso: 'Ver módulo'},
                        {padre: 'Administración / Documentos para expediciones', permiso: 'Ver módulo'},                        
                        {padre: 'Administración / Concesionarios', permiso: 'Ver módulo'},         
                        {padre: 'Administración / Instructores', permiso: 'Ver módulo'},                     
                        {padre: 'Administración / Disciplinas', permiso: 'Ver módulo'},                        
                        {padre: 'Administración / Impuestos', permiso: 'Ver módulo'},                     
                        {padre: 'Administración / Cargos', permiso: 'Ver módulo'},                    
                        {padre: 'Administración / Generador de documentos', permiso: 'Ver módulo'},                                                
                    ]
                },
                children: [
                    {
                        meta: {
                            deep: false,
                            roles: [
                                'superadmin',
                                '1be6be1c-f46b-11eb-b8a2-0242ac130002',
                            ],
                            show: [{padre: 'Administración / Usuarios', permiso: 'Ver módulo'}]
                        },
                        path: 'usuarios',
                        name: 'Usuarios',
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Lista Usuarios',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/usuarios/Usuarios.vue'
                                    ),
                            },
                            {
                                path: 'nuevo',
                                name: 'Nuevo usuario',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/usuarios/Usuario.vue'
                                    ),
                            },
                            {
                                path: 'editar/:id',
                                name: 'Editar usuario',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/usuarios/Usuario.vue'
                                    ),
                            },
                            {
                                path: 'ver/:id',
                                name: 'Ver usuario',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/usuarios/Usuario.vue'
                                    ),
                            },
                        ],
                    },
                    {
                        path: 'perfiles-y-permisos',
                        name: 'Perfiles y permisos',
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Administración / Perfiles y permisos', permiso: 'Ver módulo'},                        
                            ]
                        },
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Lista de grupos',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/perfiles-y-permisos/PerfilesPermisos.vue'
                                    ),
                            },
                            {
                                path: 'permisos/:id',
                                name: 'Configurar permisos de perfil',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/perfiles-y-permisos/EditarPerfil.vue'
                                    ),
                            },
                        ],
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Administración / Departamentos', permiso: 'Ver módulo'},                 
                            ]
                        },
                        path: 'departamentos',
                        name: 'Departamentos',
                        component: () =>
                            import(
                                '@/views/sistema/administracion/departamentos/Departamentos.vue'
                            ),
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Administración / Tipos de membresías', permiso: 'Ver módulo'},                        
                            ]
                        },
                        path: 'tipos-de-membresias',
                        name: 'Tipos de membresías',
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Lista tipos de membresia',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/tipos-membresias/TiposMembresias.vue'
                                    ),
                            },
                            {
                                path: 'nuevo-tipo-membresia',
                                name: 'Nuevo tipo de membresía',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/tipos-membresias/TipoMembresia.vue'
                                    ),
                            },
                            {
                                path: 'nuevo-tipo-membresia-grupo-selected/:grupo',
                                name: 'Nuevo tipo de membresia a grupo seleccionado',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/tipos-membresias/TipoMembresia.vue'
                                    ),
                            },
                            {
                                path: 'editar-tipo-membresia/:id',
                                name: 'Editar tipo de membresía',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/tipos-membresias/TipoMembresia.vue'
                                    ),
                            },
                        ],
                    },                    
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [{padre: 'Administración / Accesos', permiso: 'Ver módulo'}]
                        },
                        path: 'accesos',
                        name: 'Accesos',
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Lista accesos',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/accesos/Accesos.vue'
                                    ),
                            },
                        ],
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Administración / Activos', permiso: 'Ver módulo'},           
                            ]
                        },
                        path: 'activos',
                        name: 'Activos',
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Lista de activos',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/activos/Activos.vue'
                                    ),
                            },
                            {
                                path: 'nuevo-activo',
                                name: 'Nuevo activo',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/activos/Activo.vue'
                                    ),
                            },
                            {
                                path: 'editar-activo/:id',
                                name: 'Editar activo',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/activos/Activo.vue'
                                    ),
                            },
                        ],
                    },
                    {
                        meta: {
                            deep: true,
                            roles: [],
                            show: [
                                {padre: 'Administración / Productos / Almacenes', permiso: 'Ver módulo'},
                                {padre: 'Administración / Productos / Grupos de productos', permiso: 'Ver módulo'},
                                {padre: 'Administración / Productos / Lista de productos', permiso: 'Ver módulo'},                       
                            ]
                        },
                        path: 'productos',
                        name: 'Productos',
                        component: RouterView,
                        children: [
                            {
                                meta: {
                                    deep: false,
                                    roles: [],
                                    show: [
                                        {padre: 'Administración / Productos / Lista de productos', permiso: 'Ver módulo'}                        
                                    ]
                                },
                                path: 'lista',
                                name: 'Lista de productos',
                                component: RouterView,
                                redirect: {
                                    name: 'Index lista de productos'
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'Index lista de productos',
                                        component: () =>
                                            import(
                                                '@/views/sistema/administracion/productos/Productos.vue'
                                            ),
                                    },
                                    {
                                        path: 'nuevo-producto',
                                        name: 'Nuevo producto',
                                        component: () =>
                                            import(
                                                '@/views/sistema/administracion/productos/Producto.vue'
                                            ),
                                    },
                                    {
                                        path: 'nuevo-producto/solicitud/:id_solicitud',
                                        name: 'Nuevo producto por solicitud',
                                        component: () =>
                                            import(
                                                '@/views/sistema/administracion/productos/Producto.vue'
                                            ),
                                    },
                                    {
                                        path: 'editar-producto/:id',
                                        name: 'Editar producto',
                                        component: () =>
                                            import(
                                                '@/views/sistema/administracion/productos/Producto.vue'
                                            ),
                                    },
                                ],
                            },
                            {
                                meta: {
                                    deep: false,
                                    roles: [],
                                    show: [
                                        {padre: 'Administración / Productos / Grupos de productos', permiso: 'Ver módulo'}                        
                                    ]
                                },
                                path: 'grupos',
                                name: 'Grupos de productos',
                                component: RouterView,
                                redirect: {
                                    name: 'Grupos de productos index'
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'Grupos de productos index',
                                        component: () =>
                                            import(
                                                '@/views/sistema/administracion/productos/Grupos.vue'
                                            ),
                                    },
                                ],
                            },
                            {
                                meta: {
                                    deep: false,
                                    roles: [],
                                    show: [
                                        {padre: 'Administración / Productos / Almacenes', permiso: 'Ver módulo'}                        
                                    ]
                                },
                                path: 'almacenes',
                                name: 'Almacenes',
                                redirect: {
                                    name: 'Almacenes index'
                                },
                                component: RouterView,
                                children: [
                                    {
                                        path: '',
                                        name: 'Almacenes index',
                                        component: () =>
                                            import(
                                                '@/views/sistema/administracion/productos/Almacenes.vue'
                                            ),
                                    },
                                ],
                            },                            
                        ],
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Administración / Documentos para expediciones', permiso: 'Ver módulo'},                        
                            ]
                        },
                        path: 'documentos-para-expedientes',
                        name: 'Documentos para expedientes',
                        component: () =>
                            import(
                                '@/views/sistema/administracion/documentos-para-expedientes/DocsExp.vue'
                            ),
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Administración / Concesionarios', permiso: 'Ver módulo'},         
                            ]
                        },
                        path: 'concesionarios',
                        name: 'Concesionarios',
                        redirect: {
                            name: 'Lista de concesionarios'
                        },
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Lista de concesionarios',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/concesionarios/Concesionarios.vue'
                                    ),
                            },
                            {
                                path: 'nuevo-concesionario',
                                name: 'Nuevo concesionario',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/concesionarios/Concesionario.vue'
                                    ),
                            },
                            {
                                path: 'editar-concesionario/:id',
                                name: 'Editar concesionario',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/concesionarios/Concesionario.vue'
                                    ),
                            },
                        ],
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Administración / Instructores', permiso: 'Ver módulo'},                     
                            ]
                        },
                        path: 'instructores',
                        name: 'Instructores',
                        redirect: {
                            name: 'Lista de instructores'
                        },
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Lista de instructores',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/instructores/Instructores.vue'
                                    ),
                            },
                            {
                                path: 'nuevo-instructor',
                                name: 'Nuevo instructor',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/instructores/Instructor.vue'
                                    ),
                            },
                            {
                                path: 'editar-instructor/:id',
                                name: 'Editar instructor',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/instructores/Instructor.vue'
                                    ),
                            },
                        ],
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Administración / Disciplinas', permiso: 'Ver módulo'},                        
                            ]
                        },
                        path: 'disciplinas',
                        name: 'Disciplinas',
                        redirect: {
                            name: 'Lista de disciplinas'
                        },
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Lista de disciplinas',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/disciplinas/Disciplinas.vue'
                                    ),
                            },
                            {
                                path: 'nueva-clase',
                                name: 'Nueva clase',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/disciplinas/Clase.vue'
                                    ),
                            },
                            {
                                path: 'editar-clase/:id',
                                name: 'Editar clase',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/disciplinas/Clase.vue'
                                    ),
                            },
                        ],
                    },                    
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Administración / Impuestos', permiso: 'Ver módulo'},                     
                            ]
                        },
                        path: 'impuestos',
                        name: 'Impuestos',
                        redirect: {
                            name: 'Lista de impuestos'
                        },
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Lista de impuestos',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/impuestos/Impuestos.vue'
                                    ),
                            },
                        ],
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Administración / Cargos', permiso: 'Ver módulo'},                    
                            ]
                        },
                        path: 'cargos',
                        name: 'Cargos',
                        redirect: {
                            name: 'Lista de cargos'
                        },
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Lista de cargos',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/cargos/Cargos.vue'
                                    ),
                            },
                            {
                                path: 'nuevo-cargo',
                                name: 'Nuevo cargo',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/cargos/Cargo.vue'
                                    ),
                            },
                            {
                                path: 'editar-cargo/:id',
                                name: 'Editar cargo',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/cargos/Cargo.vue'
                                    ),
                            },
                        ],
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Administración / Generador de documentos', permiso: 'Ver módulo'},                        
                            ]
                        },
                        path: 'generador-documentos',
                        name: 'Generador de documentos',
                        redirect: {
                            name: 'Lista de documentos'
                        },
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Lista de documentos',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/generador-documentos/Plantillas.vue'
                                    ),
                            },
                            {
                                path: ':id_plantilla/:id_socio?',
                                name: 'Generar documento',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/generador-documentos/Generador.vue'
                                    ),
                            },
                        ],
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Administración / Control de presupuesto', permiso: 'Ver módulo'}                                                
                            ]
                        },
                        path: 'control-presupuesto',
                        name: 'Control de presupuesto',
                        redirect: {
                            name: 'Lista control de presupuesto'
                        },
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Lista control de presupuesto',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/control-presupuesto/ControlPresupuesto.vue'
                                    ),
                            },       
                            {
                                path: 'editar-presupuesto/:id/:periodo',
                                name: 'Ver presupuesto del dpto.',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/control-presupuesto/Presupuesto.vue'
                                    ),
                            },                     
                        ],
                    },
                ],
            },
            {
                path: 'socios',
                name: 'Socios',
                component: RouterView,
                meta: {
                    icon: 'icon ni ni-user-c',
                    roles: [],
                    menu: true,
                    deep: true,
                    show: [
                        {padre: 'Socios / Listado de socios', permiso: 'Ver módulo'},
                        {padre: 'Socios / Pendientes de aprobación', permiso: 'Ver módulo'},
                        {padre: 'Socios / Reporte cambios de membresía', permiso: 'Ver módulo'},
                        {padre: 'Socios / Tarjetones', permiso: 'Ver módulo'},
                        {padre: 'Socios / Propiedades / Lockers', permiso: 'Ver módulo'},
                        {padre: 'Socios / Propiedades / Carritos de golf', permiso: 'Ver módulo'},
                        {padre: 'Socios / Propiedades / Cocheras', permiso: 'Ver módulo'},
                        {padre: 'Socios / Avisos y notificaciones para socios', permiso: 'Ver módulo'},
                    ]
                },
                children: [
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Socios / Listado de socios', permiso: 'Ver módulo'},
                            ]
                        },
                        path: 'listado-socios',
                        name: 'Listado de socios',
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Listado Socios',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/listado-socios/ListaSocios.vue'
                                    ),
                            },
                            {
                                path: 'nuevo-socio',
                                name: 'Nuevo Socio',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/listado-socios/Socio.vue'
                                    ),
                            },

                            {
                                path: 'proceso-nuevo-socio/:socio/:id?',
                                name: 'Editar Proceso de Nuevo Socio',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/listado-socios/Socio.vue'
                                    ),
                            },
                            {
                                path: 'nuevo-conyuge/:socio/:id?',
                                name: 'Asignar Cónyuge',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/listado-socios/Familiar.vue'
                                    ),
                            },
                            {
                                path: 'nuevo-hijo/:socio/:id?',
                                name: 'Asignar hijo(a)',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/listado-socios/Familiar.vue'
                                    ),
                            },
                            {
                                path: 'nuevo-hijo-contuge/:socio/:id?',
                                name: 'Asignar hijo(a) de cónyuge',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/listado-socios/Familiar.vue'
                                    ),
                            },
                            {
                                path: 'cambiar-domicilio/:id',
                                name: 'Cambiar De Domicilio',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/listado-socios/editar-domicilio/EditarDomicilio.vue'
                                    ),
                            },
                            {
                                path: 'personal-servicio/visualizar-personal/:id_socio/:id',
                                name: 'Visualizar personal de servicio',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/listado-socios/personal-servicio/PersonalServicio.vue'
                                    ),
                            },
                            {
                                path: 'personal-servicio/nuevo-personal/:id_socio',
                                name: 'Nuevo personal de servicio',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/listado-socios/personal-servicio/PersonalServicio.vue'
                                    ),
                            },
                            {
                                path: 'personal-servicio/editar-personal/:id_socio/:id',
                                name: 'Editar personal de servicio',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/listado-socios/personal-servicio/PersonalServicio.vue'
                                    ),
                            },
                            {
                                path: 'cambiar-foraneo/:socio/:id?',
                                name: 'Cambiar a foraneo',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/listado-socios/cambios-membresia/CambioForaneo.vue'
                                    ),
                            },
                            {
                                path: 'cambiar-exento/:socio/:id?',
                                name: 'Cambiar a exento',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/listado-socios/cambios-membresia/CambioExento.vue'
                                    ),
                            },
                            {
                                path: 'cambiar-numerario/:socio/:id?',
                                name: 'Cambiar a numerario',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/listado-socios/cambios-membresia/CambioNumerario.vue'
                                    ),
                            },
                            {
                                path: 'cambiar-viuda/:socio/:id?',
                                name: 'Cambiar a viuda',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/listado-socios/cambios-membresia/CambioViuda.vue'
                                    ),
                            },
                            {
                                path: 'cambiar-honorario/:socio/:id?',
                                name: 'Cambiar a honorario',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/listado-socios/cambios-membresia/CambioHonorario.vue'
                                    ),
                            },
                            {
                                path: 'nuevo-matrimonio/:socio',
                                name: 'Nuevo Matrimonio',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/listado-socios/microprocesos/Matrimonio.vue'
                                    ),
                            },
                            {
                                path: 'editar-matrimonio/:socio/:id',
                                name: 'Editar Matrimonio',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/listado-socios/microprocesos/Matrimonio.vue'
                                    ),
                            },
                            {
                                path: 'nuevo-divorcio/:socio',
                                name: 'Nuevo Divorcio',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/listado-socios/microprocesos/Divorcio.vue'
                                    ),
                            },
                            {
                                path: 'nuevo-divorcio/:socio/:id',
                                name: 'Editar Divorcio',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/listado-socios/microprocesos/Divorcio.vue'
                                    ),
                            },
                            {
                                path: 'tramite-defuncion/:socio',
                                name: 'Tramite de defunción',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/listado-socios/microprocesos/Defuncion.vue'
                                    ),
                            },
                            {
                                path: 'tramite-defuncion/:socio/:id',
                                name: 'Editar Tramite De Defunción',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/listado-socios/microprocesos/Defuncion.vue'
                                    ),
                            },
                        ],
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Socios / Listado de socios desactivados', permiso: 'Ver módulo'},
                            ]
                        },
                        path: 'listado-socios-desactivados',
                        name: 'Listado de socios desactivados',
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Listado Socios Desactivados',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/listado-socios-desactivados/ListaSocios.vue'
                                    ),
                            },                            
                        ],
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Socios / Pendientes de aprobación', permiso: 'Ver módulo'},
                            ]
                        },
                        path: 'pendientes-aprobacion/:tab?',
                        name: 'Pendientes de aprobación',
                        component: RouterView,
                        redirect:{
                            name:'Index Pendientes de aprobación'
                        },
                        children: [
                            {
                                path: '',
                                name: 'Index Pendientes de aprobación',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/pendientes-aprobacion/PendientesAprobacion.vue'
                                    ),
                            },
                        ],
                    },
                    /*{
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Socios / Reporte cambios de membresía', permiso: 'Ver módulo'},
                            ]
                        },
                        path: 'reportes',
                        name: 'Reportes',
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: '',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/reportes/Reportes.vue'
                                    ),
                            },
                        ],
                    },*/
                    {
                        meta: {
                            deep: true,
                            roles: [],
                            show: [
                                {padre: 'Socios / Reporte cambios de membresía', permiso: 'Ver módulo'},
                                {padre: 'Socios / Reporte población de socios', permiso: 'Ver módulo'},
                                {padre: 'Socios / Reporte estado de cuotas', permiso: 'Ver módulo'},
                                {padre: 'Socios / Reporte bitácora de accesos', permiso: 'Ver módulo'},
                                {padre: 'Socios / Reporte accesos por día', permiso: 'Ver módulo'},
                                {padre: 'Socios / Reporte accesos invitados', permiso: 'Ver módulo'},
                                {padre: 'Socios / Reporte estatus', permiso: 'Ver módulo'},
                                {padre: 'Socios / Reporte socios foraneos', permiso: 'Ver módulo'},
                                {padre: 'Socios / Reporte acceso facial', permiso: 'Ver módulo'},
                            ]
                        },
                        path: 'socios-reportes',
                        name: 'Reportes',
                        component: RouterView,
                        children: [
                            {
                                path: 'reporte-cambios-membresia',
                                name: 'Cambios de membresia',
                                meta: {
                                    deep: true,
                                    roles: [],
                                    show: [
                                        {padre: 'Socios / Reporte cambios de membresía', permiso: 'Ver módulo'},
                                    ]
                                },
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/reportes/reporte-cambio-membresia/ReporteCambioMembresia.vue'
                                    ),
                            },
                            {
                                path: 'reporte-poblacion-socios',
                                name: 'Población de socios',
                                meta: {
                                    deep: true,
                                    roles: [],
                                    show: [
                                        {padre: 'Socios / Reporte población de socios', permiso: 'Ver módulo'},
                                    ]
                                },
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/reportes/reporte-poblacion-socios/ReportePoblacionSocios.vue'
                                    ),
                            },
                            {
                                path: 'reporte-adeudo-cuotas',
                                name: 'Adeudo de cuotas',
                                meta: {
                                    deep: true,
                                    roles: [],
                                    show: [
                                        {padre: 'Socios / Reporte estado de cuotas', permiso: 'Ver módulo'},
                                    ]
                                },
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/reportes/reporte-estado-cuotas/ReporteEstadoCuotas.vue'
                                    ),
                            },
                            {
                                path: 'reporte-bitacora-accesos',
                                name: 'Bitácora de accesos',
                                meta: {
                                    deep: true,
                                    roles: [],
                                    show: [
                                        {padre: 'Socios / Reporte bitácora de accesos', permiso: 'Ver módulo'},
                                    ]
                                },
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/reportes/reporte-bitacora-accesos/ReporteBitacoraAccesos.vue'
                                    ),
                            },
                            {
                                path: 'reporte-accesos-dia',
                                name: 'Accesos por día',
                                meta: {
                                    deep: true,
                                    roles: [],
                                    show: [
                                        {padre: 'Socios / Reporte accesos por día', permiso: 'Ver módulo'},
                                    ]
                                },
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/reportes/reporte-accesos-dia/ReporteAccesosDia.vue'
                                    ),
                            },
                            {
                                path: 'reporte-accesos-invitados',
                                name: 'Accesos invitados',
                                meta: {
                                    deep: true,
                                    roles: [],
                                    show: [
                                        {padre: 'Socios / Reporte accesos invitados', permiso: 'Ver módulo'},
                                    ]
                                },
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/reportes/reporte-accesos-invitados/ReporteAccesosInvitados.vue'
                                    ),
                            },
                            {
                                path: 'reporte-acceso-facial',
                                name: 'Acceso facial',
                                meta: {
                                    deep: true,
                                    roles: [],
                                    show: [
                                        {padre: 'Socios / Reporte acceso facial', permiso: 'Ver módulo'},
                                    ]
                                },
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/reportes/reporte-acceso-facial/ReporteAccesoFacial.vue'
                                    ),
                            },
                            {
                                path: 'reporte-estatus-foraneos',
                                name: 'Titulares foraneos',
                                meta: {
                                    deep: true,
                                    roles: [],
                                    show: [
                                        {padre: 'Socios / Reporte socios foraneos', permiso: 'Ver módulo'},
                                    ]
                                },
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/reportes/reporte-titulares-foraneos/ReporteTitularesForaneos.vue'
                                    ),
                            },
                            {
                                path: 'reporte-estatus',
                                name: 'Estatus',
                                meta: {
                                    deep: true,
                                    roles: [],
                                    show: [
                                        {padre: 'Socios / Reporte estatus', permiso: 'Ver módulo'},
                                    ]
                                },
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/reportes/reporte-estatus/ReporteEstatus.vue'
                                    ),
                            }
                        ],
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Socios / Tarjetones', permiso: 'Ver módulo'},
                            ]
                        },
                        path: 'tarjetones',
                        name: 'Tarjetones',
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Listado Tarjetones',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/tarjetones/ListaTarjetones.vue'
                                    ),
                            },
                            {
                                path: 'nuevo-tarjeton',
                                name: 'Crear tarjeton',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/tarjetones/Tarjeton.vue'
                                    ),
                            },
                            {
                                path: 'editar-tarjeton/:id',
                                name: 'Editar tarjetón',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/tarjetones/Tarjeton.vue'
                                    ),
                            },
                        ],
                    },
                    {
                        meta: {
                            deep: true,
                            roles: [],
                            show: [
                                {padre: 'Socios / Propiedades / Lockers', permiso: 'Ver módulo'},
                                {padre: 'Socios / Propiedades / Carritos de golf', permiso: 'Ver módulo'},
                                {padre: 'Socios / Propiedades / Cocheras', permiso: 'Ver módulo'},
                            ]
                        },
                        path: 'propiedades',
                        name: 'Propiedades',
                        component: RouterView,
                        children: [
                            {
                                meta: {
                                    deep: false,
                                    roles: [],
                                    show: [
                                        {padre: 'Socios / Propiedades / Lockers', permiso: 'Ver módulo'}                        
                                    ]
                                },
                                path: 'lockers',
                                name: 'Lockers',
                                component: RouterView,
                                children: [
                                    {
                                        path: '',
                                        name: 'Lista de lockers',
                                        component: () =>
                                            import(
                                                '@/views/sistema/socios/propiedades/Lockers.vue'
                                            ),
                                    },
                                    {
                                        path: 'ver-locker/:id',
                                        name: 'Ver locker',
                                        component: () =>
                                            import(
                                                '@/views/sistema/socios/propiedades/Locker.vue'
                                            ),
                                    },
                                    {
                                        path: 'nuevo-locker',
                                        name: 'Nuevo locker',
                                        component: () =>
                                            import(
                                                '@/views/sistema/socios/propiedades/Locker.vue'
                                            ),
                                    },
                                    {
                                        path: 'editar-locker/:id',
                                        name: 'Editar locker',
                                        component: () =>
                                            import(
                                                '@/views/sistema/socios/propiedades/Locker.vue'
                                            ),
                                    },
                                    {
                                        path: 'cesion-locker/:id_socio/:tipo/:id_referencia',
                                        name: 'Cesión de locker',
                                        component: () =>
                                            import(
                                                '@/views/sistema/socios/propiedades/Cesion.vue'
                                            ),
                                    },
                                ],
                            },
                            {
                                meta: {
                                    deep: false,
                                    roles: [],
                                    show: [
                                        {padre: 'Socios / Propiedades / Carritos de golf', permiso: 'Ver módulo'}                        
                                    ]
                                },
                                path: 'carritos-de-golf',
                                name: 'Carritos de Golf',
                                component: RouterView,
                                children: [
                                    {
                                        path: '',
                                        name: 'Lista de carritos',
                                        component: () =>
                                            import(
                                                '@/views/sistema/socios/propiedades/Carritos.vue'
                                            ),
                                    },
                                    {
                                        path: 'nuevo-carrito',
                                        name: 'Nuevo carrito',
                                        component: () =>
                                            import(
                                                '@/views/sistema/socios/propiedades/Carrito.vue'
                                            ),
                                    },
                                    {
                                        path: 'editar-carrito/:id',
                                        name: 'Editar carrito',
                                        component: () =>
                                            import(
                                                '@/views/sistema/socios/propiedades/Carrito.vue'
                                            ),
                                    },
                                ],
                            },
                            {
                                meta: {
                                    deep: false,
                                    roles: [],
                                    show: [
                                        {padre: 'Socios / Propiedades / Cocheras', permiso: 'Ver módulo'}                        
                                    ]
                                },
                                path: 'cocheras',
                                name: 'Cocheras',
                                component: RouterView,
                                children: [
                                    {
                                        path: '',
                                        name: 'Lista de cocheras',
                                        component: () =>
                                            import(
                                                '@/views/sistema/socios/propiedades/Cocheras.vue'
                                            ),
                                    },
                                    {
                                        path: 'nueva-cochera',
                                        name: 'Nueva cochera',
                                        component: () =>
                                            import(
                                                '@/views/sistema/socios/propiedades/Cochera.vue'
                                            ),
                                    },
                                    {
                                        path: 'editar-cochera/:id',
                                        name: 'Editar cochera',
                                        component: () =>
                                            import(
                                                '@/views/sistema/socios/propiedades/Cochera.vue'
                                            ),
                                    },
                                    {
                                        path: 'ver-cochera/:id',
                                        name: 'Ver cochera',
                                        component: () =>
                                            import(
                                                '@/views/sistema/socios/propiedades/CocheraVer.vue'
                                            ),
                                    },
                                    {
                                        path: 'cesion-cochera/:id_socio/:tipo/:id_referencia',
                                        name: 'Cesión de cochera',
                                        component: () =>
                                            import(
                                                '@/views/sistema/socios/propiedades/Cesion.vue'
                                            ),
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Socios / Avisos para socios', permiso: 'Ver módulo'},
                            ]
                        },
                        path: 'avisos',
                        name: 'Avisos para socios',
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Avisos',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/avisos/Avisos.vue'
                                    ),
                            },
                        ],
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Socios / Avisos y notificaciones para socios', permiso: 'Ver módulo'},
                            ]
                        },
                        path: 'avisos-notificaciones',
                        name: 'Notificaciones para socios',
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Notificaciones',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/avisos-notificaciones/AvisosNotificaciones.vue'
                                    ),
                            },
                        ],
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Socios / Procesos automaticos', permiso: 'Ver módulo'},
                            ]
                        },
                        path: 'procesos-automaticos',
                        name: 'Procesos automaticos',
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Index Procesos automaticos',
                                component: () =>
                                    import(
                                        '@/views/sistema/socios/cron-jobs/CronJobs.vue'
                                    ),
                            },                            
                        ],
                    },
                ],
            },
            {
                path: 'accesos-club',
                name: 'Accesos al club',
                component: RouterView,
                meta: {
                    icon: 'icon ni ni-shield-half',
                    roles: [],
                    menu: true,
                    deep: true,
                    show: [
                        {padre: 'Accesos al club / Acceso', permiso: 'Ver módulo'},
                    ]
                },
                children: [
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Accesos al club / Acceso', permiso: 'Ver módulo'},
                            ]
                        },
                        path: 'acceso',
                        name: 'Acceso',
                        component: RouterView,
                        children: [
                            {
                                path: ':id?',
                                name: 'Acceso al club',
                                component: () =>
                                    import(
                                        '@/views/sistema/accesos-club/acceso/Acceso.vue'
                                    ),
                            },
                            {
                                path: ':id/nuevo-invitado',
                                name: 'Añadir invitado',
                                component: () =>
                                    import(
                                        '@/views/sistema/accesos-club/acceso/Invitado.vue'
                                    ),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'cobranzas',
                name: 'Cobranzas',
                component: RouterView,
                meta: {
                    icon: 'icon ni ni-money',
                    roles: [],
                    menu: true,
                    deep: true,
                    show: [
                        {padre: 'Cobranzas / Cobrar cargos', permiso: 'Ver módulo'},
                        {padre: 'Cobranzas / Histórico de cargos', permiso: 'Ver módulo'},
                        {padre: 'Cobranzas / Histórico de pagos', permiso: 'Ver módulo'},
                        {padre: 'Cobranzas / Histórico de transacciones', permiso: 'Ver módulo'},
                        {padre: 'Cobranzas / Convenios', permiso: 'Ver módulo'},
                        {padre: 'Cobranzas / Pagos domiciliados / Configuración', permiso: 'Ver módulo'},
                        {padre: 'Cobranzas / Pagos domiciliados / Bitácora', permiso: 'Ver módulo'}                  
                    ]
                },
                children: [
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Cobranzas / Cobrar cargos', permiso: 'Ver módulo'},
                            ]
                        },
                        path: 'cobrar-cargos',
                        name: 'Cobrar cargos',
                        redirect:{
                            name:'Agregar cargos'
                        },
                        component: RouterView,
                        children: [
                            {
                                path: 'cargos-por-pagar',
                                name: 'Lista de cargos por pagar',
                                component: () =>
                                    import(
                                        '@/views/sistema/cobranzas/cobrar-cargos/CobrarCargos.vue'
                                    ),
                            },
                            {
                                path: '',
                                name: 'Agregar cargos',
                                component: () =>
                                    import(
                                        '@/views/sistema/cobranzas/cobrar-cargos/AgregarCargos.vue'
                                    ),
                            },
                            {
                                path: 'cobrar-cargo/:checkout',
                                name: 'Cobrar Cargo',
                                component: () =>
                                    import(
                                        '@/views/sistema/cobranzas/cobrar-cargos/CobrarCargo.vue'
                                    ),
                            },
                        ],
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Cobranzas / Histórico de cargos', permiso: 'Ver módulo'},
                            ]
                        },
                        path: 'historico-cargos',
                        name: 'Histórico de cargos',
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Listado de cargos',
                                component: () =>
                                    import(
                                        '@/views/sistema/cobranzas/historico-cargos/Cargos.vue'
                                    ),
                            },
                            {
                                path: 'cargo/:id',
                                name: 'Cargo',
                                component: () =>
                                import(
                                    '@/views/sistema/cobranzas/historico-cargos/Cargo.vue'
                                    ),
                                },
                            ],
                        },
                        {
                            meta: {
                                deep: false,
                                roles: [],
                                show: [
                                    {padre: 'Cobranzas / Histórico de pagos', permiso: 'Ver módulo'},
                                ]
                            },
                        path: 'historico-pagos',
                        name: 'Historico de pagos',
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Listado de pagos',
                                component: () =>
                                    import(
                                        '@/views/sistema/cobranzas/historico-pagos/Pagos.vue'
                                    ),
                            },
                            {
                                path: 'detalle-pago/:id',
                                name: 'Detalle de pago',
                                component: () =>
                                    import(
                                        '@/views/sistema/cobranzas/historico-pagos/Pago.vue'
                                    ),
                            },
                        ],
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Cobranzas / Histórico de transacciones', permiso: 'Ver módulo'},
                            ]
                        },
                        path: 'historico-transacciones',
                        name: 'Histórico de transacciones',
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Lista de transacciones',
                                component: () =>
                                    import(
                                        '@/views/sistema/cobranzas/historico-transacciones/Transacciones.vue'
                                    ),
                            },
                            {
                                path: 'transaccion/:id',
                                name: 'Transacción',
                                component: () =>
                                    import(
                                        '@/views/sistema/cobranzas/historico-transacciones/Transaccion.vue'
                                    ),
                            },
                        ],
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Cobranzas / Histórico de pagos online', permiso: 'Ver módulo'},
                            ]
                        },
                        path: 'historico-pagos-online',
                        name: 'Histórico de pagos online',
                        component: RouterView,
                        redirect: {
                            name:'Index pagos online'
                        },
                        children: [
                            {
                                path: '',
                                name: 'Index pagos online',
                                component: () =>
                                    import(
                                        '@/views/sistema/cobranzas/historico-pagos-online/PagosOnline.vue'
                                    ),
                            },
                        ],
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Cobranzas / Convenios', permiso: 'Ver módulo'},
                            ]
                        },
                        path: 'convenios',
                        name: 'Convenios',
                        component: RouterView,
                        redirect:{
                            name:'Index Convenios'
                        },
                        children: [
                            {
                                path: '',
                                name: 'Index Convenios',
                                component: () =>
                                    import(
                                        '@/views/sistema/cobranzas/convenios/Convenios.vue'
                                    ),
                            },
                            {
                                path: 'crear-convenio/:id_socio/:id_convenio?',
                                name: 'Crear convenio',
                                component: () =>
                                    import(
                                        '@/views/sistema/cobranzas/convenios/Convenio.vue'
                                    ),
                            },
                            // {
                            //   path: 'configurar-convenio/:id_convenio',
                            //   name: 'Configurar convenio',
                            //   component: () => import('@/views/sistema/cobranzas/convenios/ConfigurarConvenio.vue'),
                            // },
                            {
                                path: 'configurar-convenio/:id_convenio/:mode',
                                name: 'Configurar convenio',
                                component: () =>
                                    import(
                                        '@/views/sistema/cobranzas/convenios/ConfigurarConvenio.vue'
                                    ),
                            },
                        ],
                    },
                    {
                        meta: {
                            deep: true,
                            roles: [],
                            show: [
                                {padre: 'Cobranzas / Pagos domiciliados / Configuración', permiso: 'Ver módulo'},
                                {padre: 'Cobranzas / Pagos domiciliados / Bitácora', permiso: 'Ver módulo'}                      
                            ]
                        },
                        path: 'pagos-domiciliados',
                        name: 'Pagos domiciliados',
                        component: RouterView,
                        children: [
                            {
                                meta: {
                                    deep: false,
                                    roles: [],
                                    show: [
                                        {padre: 'Cobranzas / Pagos domiciliados / Configuración', permiso: 'Ver módulo'}                        
                                    ]
                                },
                                path: 'configuracion',
                                name: 'Configuración',
                                component: RouterView,
                                redirect:{
                                    name:'Index Configuracion'
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'Index Configuracion',
                                        component: () =>
                                            import(
                                                '@/views/sistema/cobranzas/pagos-domiciliados/PagosDomiciliados.vue'
                                            ),
                                    },
                                    {
                                        path: 'cargos',
                                        name: 'Pagos domiciliados cargos',
                                        component: () =>
                                            import(
                                                '@/views/sistema/cobranzas/pagos-domiciliados/PagosDomiciliadosCargos.vue'
                                            ),
                                    },
                                    {
                                        path: 'resultados/:id_archivo',
                                        name: 'Pagos domiciliados resultados',
                                        component: () =>
                                            import(
                                                '@/views/sistema/cobranzas/pagos-domiciliados/PagosDomiciliadosResultados.vue'
                                            ),
                                    },
                                ],
                            },
                            {
                                meta: {
                                    deep: false,
                                    roles: [],
                                    show: [
                                        {padre: 'Cobranzas / Pagos domiciliados / Bitácora', permiso: 'Ver módulo'}                        
                                    ]
                                },
                                path: 'bitacora',
                                name: 'Bitacora',
                                component: () =>
                                    import(
                                        '@/views/sistema/cobranzas/pagos-domiciliados/PagosDomiciliadosBitacora.vue'
                                    ),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'deportes',
                name: 'Deportes',
                component: RouterView,
                meta: {
                    icon: 'icon ni ni-help-alt',
                    roles: [],
                    menu: true,
                    deep: true,
                    show: [
                        {padre: 'Deportes / Inscripciones', permiso: 'Ver módulo'},
                        {padre: 'Deportes / Torneos', permiso: 'Ver módulo'}
                    ]
                },
                children: [
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Deportes / Inscripciones', permiso: 'Ver módulo'}
                            ]
                        },
                        path: 'inscripciones',
                        name: 'Inscripciones',
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Index inscripciones',
                                component: () =>
                                    import(
                                        '@/views/sistema/deportes/Inscripciones.vue'
                                    ),
                            },
                            {
                                path: 'nueva-inscripcion',
                                name: 'Nueva inscripción',
                                component: () =>
                                    import(
                                        '@/views/sistema/deportes/Inscripcion.vue'
                                    ),
                            },
                            {
                                path: 'ver-inscripcion/:inscripcion_id',
                                name: 'Ver inscripción',
                                component: () =>
                                    import(
                                        '@/views/sistema/deportes/Inscripcion.vue'
                                    ),
                            },
                        ],
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Deportes / Torneos', permiso: 'Ver módulo'}
                            ]
                        },
                        path: 'torneos',
                        name: 'Torneos',
                        component: RouterView,
                        redirect:{
                            name:'Index Torneos'
                        },
                        children: [
                            {
                                path: '',
                                name: 'Index Torneos',
                                component: () =>
                                    import(
                                        '@/views/sistema/deportes/Torneos.vue'
                                    ),
                            },
                            {
                                path: 'nuevo-torneo',
                                name: 'Nuevo torneo',
                                component: () =>
                                    import(
                                        '@/views/sistema/deportes/Torneo.vue'
                                    ),
                            },
                            {
                                path: 'editar-torneo/:id',
                                name: 'Editar torneo',
                                component: () =>
                                    import(
                                        '@/views/sistema/deportes/Torneo.vue'
                                    ),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'compras',
                name: 'Compras',
                component: RouterView,
                meta: {
                    icon: 'icon ni ni-cart',
                    roles: [],
                    menu: true,
                    deep: true,
                    show: [
                        { padre: 'Compras / Proveedores', permiso: 'Ver módulo' },
                        { padre: 'Compras / Requisiciones', permiso:'Ver módulo' },
                        { padre: 'Compras / Órdenes de compra', permiso:'Ver módulo' },
                        { padre: 'Compras / Cuentas Por Pagar', permiso:'Ver módulo' }
                    ]
                },
                children: [
                    {
                        meta: {
                            deep: true,
                            roles: [],
                            show: [
                                {padre: 'Compras / Proveedores', permiso: 'Ver módulo'}
                            ]
                        },
                        path: 'proveedores',
                        name: 'Proveedores',
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Index Proveedores',
                                component: () =>
                                    import(
                                        '@/views/sistema/compras/proveedores/Proveedores.vue'
                                    ),
                            },
                            {
                                path: 'nuevo-proveedor/:tab?',
                                name: 'Nuevo Proveedor',
                                component: () =>
                                    import(
                                        '@/views/sistema/compras/proveedores/Proveedor.vue'
                                    ),
                            },
                            {
                                path: 'editar-proveedor/:provider_id/:tab?',
                                name: 'Editar Proveedor',
                                component: () =>
                                    import(
                                        '@/views/sistema/compras/proveedores/Proveedor.vue'
                                    ),
                            },
                            {
                                path:'cotizaciones',
                                name:'Cotizaciones',
                                component: RouterView,
                                meta: {
                                    menu: true,
                                    deep: false,
                                    show: [
                                        { padre: 'Compras / Cotizaciones', permiso: 'Ver módulo' },
                                    ]
                                },
                                children: [
                                    {
                                        
                                        path: '',
                                        name: 'Mis cotizaciones',
                                        component: () => import(
                                            '@/views/proveedor/cotizaciones/Cotizaciones.vue'
                                        ),
                                    }
                                ]
                            },
                            {
                                path:'ordenes-compra',
                                name:'Ordenes de compra',
                                component: RouterView,
                                meta: {
                                    menu: true,
                                    deep: false,
                                    show: [
                                        { padre: 'Compras / Órdenes de compra', permiso: 'Ver módulo como proveedor' },
                                    ]
                                },
                                children: [
                                    {
                                        
                                        path: '',
                                        name: 'Mis Ordenes de Compra',
                                        component: () => import(
                                            '@/views/proveedor/ordenes-compra/OrdenesCompra.vue'
                                        ),
                                    },
                                    {
                                        
                                        path: 'ver-orden/:id',
                                        name: 'Mi Orden de compra',
                                        component: () => import(
                                            '@/views/proveedor/ordenes-compra/OrdenCompra.vue'
                                        ),
                                    },
                
                                ]
                            }
                        ],
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show:[
                                { padre: 'Compras / Requisiciones', permiso:'Ver módulo' }
                            ]
                        },
                        path: 'requisiciones',
                        name: 'Requisiciones',
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Index Requisiciones',
                                component: () =>
                                    import(
                                        '@/views/sistema/compras/requisiciones/Requisiciones.vue'
                                    ),
                            },
                            {
                                path: 'nueva-requisicion',
                                name: 'Nueva Requisición',
                                component: () =>
                                    import(
                                        '@/views/sistema/compras/requisiciones/Requisicion.vue'
                                    ),
                            },
                            {
                                path: 'continuar-requisicion',
                                name: 'Continuar Nueva Requisición',
                                component: () =>
                                    import(
                                        '@/views/sistema/compras/requisiciones/Requisicion.vue'
                                    ),
                            },
                            {
                                path: 'editar-requisicion/:id/:tab?',
                                name: 'Editar Requisición',
                                component: () =>
                                    import(
                                        '@/views/sistema/compras/requisiciones/Requisicion.vue'
                                    ),
                            },
                        ],
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show:[
                                { padre: 'Compras / Órdenes de compra', permiso:'Ver módulo' }
                            ]
                        },
                        path: 'ordenes-compra',
                        name: 'Órdenes de compra',
                        redirect: (to:unknown) => {
                            return { name: 'Index ordenes de compra'}
                        },
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Index ordenes de compra',
                                component: () =>
                                    import(
                                        '@/views/sistema/compras/ordenes-compra/OrdenesCompra.vue'
                                    ),
                            },
                            {
                                path:'ver-orden/:id?',
                                name:'Orden de compra',
                                component: () =>
                                    import(
                                        '@/views/sistema/compras/ordenes-compra/OrdenCompra.vue'
                                    ),
                            }
                        ],
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show:[
                                { padre: 'Compras / Cuentas Por Pagar', permiso:'Ver módulo' }
                            ]
                        },
                        path: 'cuentas-por-pagar',
                        name: 'Cuentas Por Pagar',

                        redirect: (to:unknown) => {
                            return { name: 'Index Cuentas Por Pagar'}
                        },
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Index Cuentas Por Pagar',
                                component: () =>
                                    import(
                                        '@/views/sistema/compras/cuentas-pagar/CuentasPorPagar.vue'
                                    ),
                            },
                            {
                                path: 'generar-poliza',
                                name: 'Generar Poliza',
                                component: () =>
                                    import(
                                        '@/views/sistema/compras/cuentas-pagar/Poliza.vue'
                                    ),
                            },
                            {
                                path: 'poliza/:id',
                                name: 'Editar Poliza',
                                component: () =>
                                    import(
                                        '@/views/sistema/compras/cuentas-pagar/Poliza.vue'
                                    ),
                            },
                            {
                                path: 'pagos-poliza/:id',
                                name: 'Poliza',
                                component: () =>
                                    import(
                                        '@/views/sistema/compras/cuentas-pagar/PagosPoliza.vue'
                                    ),
                            }
                        ],
                    }
                ],
            },
            {
                path: 'almacen',
                name: 'Almacen',
                component: RouterView,
                meta: {
                    title: 'Almacén',
                    icon: 'icon ni ni-building',
                    roles: [],
                    menu: true,
                    deep: true,
                    show: [
                        {padre: 'Almacén / Inventarios', permiso: 'Ver módulo'},
                        {padre: 'Almacén / Entradas', permiso: 'Ver módulo'},
                        {padre: 'Almacén / Salidas', permiso: 'Ver módulo'}
                    ]
                },
                children: [
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Almacén / Inventarios', permiso: 'Ver módulo'}                        
                            ]
                        },
                        path: 'inventarios',
                        name: 'Inventarios',
                        redirect: {
                            name: 'Listado Inventarios'
                        },
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Listado Inventarios',
                                component: () =>
                                    import(
                                        '@/views/sistema/almacen/Inventarios.vue'
                                    ),
                            },
                            {
                                path: 'inventario/:id_inventario',
                                name: 'Inventario',
                                component: () =>
                                    import(
                                        '@/views/sistema/almacen/Inventario.vue'
                                    ),
                            },                                 
                        ],
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Almacén / Entradas', permiso: 'Ver módulo'}                        
                            ]
                        },
                        path: 'entradas',
                        name: 'Entradas',
                        redirect: {
                            name: 'Listado Entradas'
                        },
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Listado Entradas',
                                component: () =>
                                    import(
                                        '@/views/sistema/almacen/Entradas.vue'
                                    ),
                            },
                            {
                                path: 'entrada/:id_entrada',
                                name: 'Entrada',
                                component: () =>
                                    import(
                                        '@/views/sistema/almacen/Entrada.vue'
                                    ),
                            },
                            {
                                path: 'entrada-detalles/:id_entrada',
                                name: 'Entrada detalles',
                                component: () =>
                                    import(
                                        '@/views/sistema/almacen/EntradaDetalles.vue'
                                    ),
                            },                    
                        ],
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Almacén / Salidas', permiso: 'Ver módulo'}                        
                            ]
                        },
                        path: 'salidas',
                        name: 'Salidas',
                        component: RouterView,
                        redirect:{
                            name: 'Listado Salidas'
                        },
                        children: [
                            {
                                path: '',
                                name: 'Listado Salidas',
                                component: () =>
                                    import(
                                        '@/views/sistema/almacen/Salidas.vue'
                                    ),
                            },
                            {
                                path: 'nueva-salida/:id_salida?',
                                name: 'Nueva salida',
                                component: () =>
                                    import(
                                        '@/views/sistema/almacen/NuevaSalida.vue'
                                    ),
                            }, 
                            {
                                path: 'salida/:id_salida',
                                name: 'Salida',
                                component: () =>
                                    import(
                                        '@/views/sistema/almacen/Salida.vue'
                                    ),
                            },
                            {
                                path: 'devolver-salida/:id_salida',
                                name: 'Devolver salida',
                                component: () =>
                                    import(
                                        '@/views/sistema/almacen/DevolverSalida.vue'
                                    ),
                            },                
                        ],
                    },
                ]
            },
            {
                path: 'servicios',
                name: 'Modulo de servicios',
                component: RouterView,
                meta: {
                    title: 'Servicios',
                    icon: 'icon ni ni-heart-fill',
                    roles: [],
                    menu: true,
                    deep: true,
                    show: [
                        {padre: 'Servicios / Agenda de servicios', permiso: 'Ver módulo'},
                        {padre: 'Servicios / Lista de citas', permiso: 'Ver módulo'},
                        {padre: 'Servicios / Listado de Servicios', permiso: 'Ver módulo'},
                        {padre: 'Servicios / Prestador de servicios', permiso: 'Ver módulo'}   
                    ]
                },
                children: [
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Servicios / Agenda de servicios', permiso: 'Ver módulo'}
                            ]
                        },
                        path: 'agenda',
                        name: 'Agenda de servicios',
                        component: RouterView,
                        redirect:{
                            name:'Index Agenda de servicios'
                        },
                        children: [
                            {
                                path: '',
                                name: 'Index Agenda de servicios',
                                component: () =>
                                    import(
                                        '@/views/sistema/servicios/agenda/Agenda.vue'
                                    ),
                            },
                        ],
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Servicios / Lista de citas', permiso: 'Ver módulo'}
                            ]
                        },
                        path: 'citas-servicios',
                        name: 'Lista de citas',
                        component: RouterView,
                        redirect:{
                            name:'Index Lista de citas'
                        },
                        children: [
                            {
                                path: '',
                                name: 'Index Lista de citas',
                                component: () =>
                                    import(
                                        '@/views/sistema/servicios/agenda/ListaCitas.vue'
                                    ),
                            },
                        ],
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Servicios / Listado de Servicios', permiso: 'Ver módulo'},         
                            ]
                        },
                        path: 'servicios',
                        name: 'Servicios',
                        redirect: {
                            name: 'Lista de servicios'
                        },
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Lista de servicios',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/servicios/Servicios.vue'
                                    ),
                            },
                        ],
                    },
                    {
                        meta: {
                            deep: false,
                            roles: [],
                            show: [
                                {padre: 'Servicios / Prestador de servicios', permiso: 'Ver módulo'}                                                
                            ]
                        },
                        path: 'prestador-servicios',
                        name: 'Prestador de servicios',
                        redirect: {
                            name: 'Lista prestadores de servicios'
                        },
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Lista prestadores de servicios',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/prestadores-servicios/PrestadoresServicios.vue'
                                    ),
                            },
                            {
                                path: 'editar-prestador/:id',
                                name: 'Editar prestador',
                                component: () =>
                                    import(
                                        '@/views/sistema/administracion/prestadores-servicios/PrestadorServicio.vue'
                                    ),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'perfil-usuario',
                name: 'Perfil de usuario',
                component: () =>
                    import('@/views/sistema/usuario/PerfilUsuario.vue'),
                meta: {
                    roles: [],
                },
            },
            {
                path: 'mantenimientos',
                name: 'Mantenimientos',
                component: RouterView,
                redirect: { name: 'Listado mantenimientos' },
                meta: {
                    icon: 'icon ni ni-meter',
                    roles: [],
                    menu: true,
                    deep: true,
                    show: [
                        {padre: 'Mantenimientos', permiso: 'Ver módulo'}
                    ]
                },
                children: [
                    {
                        meta: {
                            show: [
                                {padre: 'Mantenimientos', permiso: 'Ver módulo'}
                            ]
                        },
                        path: 'listado/:tab?',
                        name: 'Listado mantenimientos',
                        component: () =>
                            import('@/views/sistema/mantenimientos/Mantenimientos.vue'),
                    },
                    {
                        path:'solicitud/:id_solicitud?',
                        name:'Solicitud mantenimiento',
                        component: () =>
                            import('@/views/sistema/mantenimientos/tabs/Solicitud.vue'),
                    },
                    {
                        path:'mantenimiento/:id_mantenimiento?',
                        name:'Mantenimiento',
                        component: () =>
                            import('@/views/sistema/mantenimientos/tabs/Mantenimiento.vue'),
                    },
                    {
                        path:'activo/:id_activo?',
                        name:'Historial activo',
                        component: () =>
                            import('@/views/sistema/mantenimientos/tabs/Activo.vue'),
                    }          
                ],
            },
        ],
    },
    {
        path: '/:catchAll(.*)',
        component: () => import('@/views/404.vue'),
    },
];
const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta.auth && (!user.value.isInitialised || token.value === null)) {
        next({ name: 'Login', replace: true });
    } else if(to.name === 'Login' && user.value.isInitialised && user.value.role === 'proveedor'){
        next({ name: 'Proveedor', replace: true });
    } else if (to.name === 'Login' && user.value.isInitialised ) {
        next({ name: 'Sistema', replace: true });
    } else {
        next();
        // if (to.meta.roles) {
        //   if (to.meta.roles.includes(user.value.role)) {
        //     next();
        //   } else {
        //     next('/404');
        //   }
        // } else {
        // }
    }
});

export default router;
